.flex {
    display: flex;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .mt-2 {
    margin-top: 0.5rem; /* Assuming the Tailwind CSS mt-2 corresponds to a 0.5rem margin */
  }
  
  .hidden {
    display: none;
  }
  
  .block {
    display: block;
    border: none;
    background: transparent;
  }
  
  .h-8 {
    height: 2rem; /* Adjust as needed */
  }
  
  .w-4 {
    width: 1rem; /* Adjust as needed */
  }
  
  .text-indigo-700 {
    color: #4f46e5; /* Adjust as needed */
  }
  
  .absolute {
    position: absolute;
  }
  
  .z-10 {
    z-index: 10;
  }
  
  .ml-2 {
    margin-left: 0.5rem; /* Adjust as needed */
  }
  
  .h-8 {
    height: 2rem; /* Adjust as needed */
  }
  
  .w-8 {
    width: 2rem; /* Adjust as needed */
  }
  
  .animate-bounce {
    animation: bounce 0.7s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  
  .h-5 {
    height: 1.25rem; /* Adjust as needed */
  }
  
  .border-l-2 {
    border-left-width: 2px;
  }
  
  .border-gray-200 {
    border-color: #edf2f7; /* Adjust as needed */
  }
  
  .border-r-2 {
    border-right-width: 2px;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  