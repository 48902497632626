@import "dispute";

@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:wght@400;500;600&display=swap");

* {
  font-family: "Pacifico", cursive;
  font-family: "Poppins", sans-serif;
}
.bg-gradient-tealpot {
  background: linear-gradient(87deg, #31ae93 0, #008080 100%) !important;
}
.bg-red-tealpot {
  background: linear-gradient(87deg, #f72626 0, #db4242 100%) !important;
}

$theme-colors: (
  "site-primary": #008080,
  "site-primary-lite": hsl(218, 83%, 55%),
  "site-muted": #9899b3,
  "site-lite": #f8f7fc,
  "site-secondary": #eaeaf0,
  "site-gray": #e8f0ff,
  "site-lite-gray": #f7f8fa,
  "site-sky-lite": #d9e4f8,
  "site-main": #f7f8fa,
  "site-gray-500": #666885,
  "site-gray-600": #474967,
  "site-gray-900": #101128,
  "site-white": #ffffff,
  "site-red": #e74e69,
  "site-green": #2b9943,
);

.tealpotLogo {
  height: 40px;
  margin-top: 20px;
  width: 100%;
  object-fit: contain;
}
.tealpotLogombl {
  height: 30px;
  // margin-top: 20px;
  width: 100%;
  object-fit: contain;
}
.sidebarIcon {
  color: #008080 !important;
}

.actionButton {
  background-color: #008080 !important;
  color: white;
}
.authPage {
  height: 100vh !important;
  background: linear-gradient(87deg, #31ae93 0, #008080 100%) !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* src/App.css */
.chat-container {
  height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}

.message {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  word-wrap: break-word;
}

.message-left {
  background-color: #f0f0f0;
  text-align: left;
}

.message-right {
  background-color: #007bff;
  color: #fff;
  text-align: right;
}

// Pagination css

@media (max-width: 768px) {
  .page-item .page-link,
  .page-item span {
    width: 30px;
    height: 30px;
    font-size: 0.675rem;
  }
}
.countries_card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    font-size: 12px;
  }
}
.main-sidebar {
  overflow-y: auto !important;
  @media (max-width: 768px) {
    overflow-y: visible !important;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a5a5a5;
    border-radius: 12px;
    &:hover {
      background-color: #a5a5a5;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; // Set the color of the scrollbar track
  }
}
.table-responsive {
  overflow-x: auto !important;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a5a5a5;
    border-radius: 12px;
    &:hover {
      background-color: #a5a5a5;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; // Set the color of the scrollbar track
  }
}
.order__chat-modal {
  max-width: 70% !important;
}

.conversation-container {
  height: 500px;
  overflow-y: auto;
}

.supportchat-container {
  height: 382px;
  overflow-y: auto;
  padding: 10px;
}
.supportchat-container-custom_height {
  height: 430px;
}

.chat-heading {
  font-size: 30px;
  font-weight: 400;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-scrollbar {
  overflow-y: auto !important;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.chat-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.chat-scrollbar::-webkit-scrollbar-thumb {
  background-color: #a5a5a5;
  border-radius: 12px;
}

.chat-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a5a5a5;
}

.chat-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.text-small {
  font-size: 14px;
}

.wrapper {
  width: 250px;
  margin-right: 20px;
  position: relative;

  .textfield {
    width: 84%;

    &-input-field {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    @media screen and (max-width: 769px) and (min-width: 767px) {
      width: 83% !important;
    }
  }
  .dropdown {
    width: 16%;
    border-color: #cdc7c7 !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &-input-field {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .dropDownList {
    position: absolute;
    z-index: 10;
    width: 98%;
    border-radius: 5px;
    .dropdown-list:hover {
      cursor: default;
    }
  }
  @media screen and (max-width: 379px) {
    width: 180px;
  }
  @media screen and (max-width: 325px) {
    width: 150px;
  }
}
.custom_search {
  @media screen and (max-width: 817px) and (min-width: 768px) {
    width: 85% !important;
    // margin-left: 20px;
  }
  @media screen and (max-width: 768px) and (min-width: 767px) {
    width: 90% !important;
    // margin-left: 20px;
  }
  @media screen and (max-width: 402.99px) and (min-width: 385.99px) {
    width: 350px !important;
    // margin-left: 20px;
  }
  @media screen and (max-width: 385.98px) and (min-width: 377.99px) {
    width: 330px !important;
    // margin-left: 20px;
  }
}

.aligned-class {
  grid-column: span 11;

  @media (min-width: 640px) {
    grid-column: span 8;
    grid-column-start: 2;
  }

  @media (min-width: 768px) {
    grid-column: span 10;
    grid-column-start: 3;
  }

  @media (min-width: 1024px) {
    grid-column: span 8;
    grid-column-start: 5;
  }

  @media (min-width: 1280px) {
    grid-column: span 8;
    grid-column-start: 5;
  }

  .profile-image {
    width: 3rem;
    height: 3rem;
    @media (max-width: 1025px) {
      visibility: visible;
    }
    @media (max-width: 769px) {
      visibility: visible;
    }
    @media (max-width: 426px) {
      visibility: hidden;
      width: 0%;
      margin: 0% !important;
    }
  }

  .video-message {
    padding: 0.75rem 1rem;
    margin-bottom: 0.25rem;
    background-color: white;
    border: 1px solid #f0e2e3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    display: flex;
    width: 80%;
    flex-direction: column;
    gap: 0.5rem;
  }

  .text-message {
    padding: 0.5rem 1rem;
    background-color: rgba(13, 45, 85, 0.1);
    border-radius: 0.375rem;
    margin-bottom: 0rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    //   max-width: 355px;
    //   @media screen and (max-width: 1026px) {
    //     max-width: 280px !important;
    //   }
    //   @media screen and (max-width: 769px) {
    //     max-width: 240px !important;
    //   }
    //   @media screen and (max-width: 426px) {
    //     max-width: 240px !important;
    //   }
    //   @media screen and (max-width: 379px) {
    //     // width: 150px !important;
    //     font-size: 0.5rem !important;
    //   }
  }

  .video-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-sender {
    font-size: 1.05rem;
    font-weight: 600;
    @media (max-width: 1026px) {
      font-size: 1.05rem;
    }
    @media (max-width: 769px) {
      font-size: 1rem;
    }
    @media (max-width: 426px) {
      font-size: 0.8rem;
    }

    /* Large devices (desktops) */
  }

  .message-content {
    font-size: 1rem;
    margin-bottom: 0px;
    max-width: 355px;

    @media (max-width: 1026px) {
      font-size: 1.05rem !important;
      max-width: 280px !important;
    }
    @media (max-width: 769px) {
      font-size: 1rem !important;
      max-width: 240px !important;
    }
    @media (max-width: 426px) {
      font-size: 0.8rem !important;
      max-width: 240px !important;
    }
    @media screen and (max-width: 379px) {
      font-size: 0.5rem !important;
    }
  }

  .time-container {
    display: flex;
    justify-content: flex-end;
  }

  .link {
    color: #3b82f6;
  }

  .attachment-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .attachment-link {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }

  .attachment {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
  }

  .footer-image {
    width: 1rem;
    height: 1rem;
    margin-top: 0.25rem;
    margin-right: 0.5rem;
  }

  .attachment-text {
    font-size: 1.25rem;
    @media (max-width: 1025px) {
      font-size: 1.25rem;
    }
    @media (max-width: 769px) {
      font-size: 1rem;
    }
    @media (max-width: 426px) {
      font-size: 0.8rem;
    }
  }
}
///Chat Footer
.emoji {
  position: absolute;
  bottom: 100% !important;
  left: 0 !important;
  z-index: 1 !important;
}
.footer_icon {
  height: 50%;
  width: 50%;
  //   @media (max-width: 1025px) {

  //     font-size: 1.25rem;

  // }
  //   @media (max-width: 769px) {

  //         font-size: 1rem;

  // }
  // @media (max-width: 426px) {

  //   font-size: 0.80rem;

  // }
}
.input-file {
  @media (max-width: 1025px) {
    font-size: 1.25rem;
  }
  @media (max-width: 769px) {
    font-size: 1rem;
  }
  @media (max-width: 426px) {
    margin-top: 10%;
    margin-left: 0% !important;
  }
}

.message-input {
  margin: 0;
  width: 100%;
  border: 0;

  resize: none;
  background-color: transparent;
  padding: 10px;
  padding-right: 2.5rem;
  font-size: 14px;
  line-height: 1.5;
  height: fit-content;
  max-height: 70px;
  overflow-y: scroll;

  /* Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: #c3c1d8 #f2fdff;
  }

  /* Chrome, Edge and Safari */
  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: #c3c1d8;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #e1ddf1;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #c3c1d8;
  }
}

// ::-webkit-scrollbar {
//   width: 0.111rem;
//   height: 0rem;
// }
// ::-webkit-scrollbar-track {
//   background: theme-color("site-secondary1");
// }
// ::-webkit-scrollbar-thumb {
//   background-color: #cecece; /* color of the scroll thumb */
//   border-radius: 1.25rem; /* roundness of the scroll thumb */
// }
// ::-webkit-scrollbar-thumb:hover {
//   background: #a8a8a8;
// }
//// Convertion unread meesage list color
.blue {
  background-color: rgba(222, 222, 245, 0.893);
}
.dark-blue {
  background-color: rgba(74, 74, 248, 0.893);
}
.w-max {
  width: max-content;
}
.h-max {
  height: max-content;
}

.list_items_initiate_chat:hover {
  background-color: #008080;
  color: white;
}

.custom_height {
  height: 290px !important;
}
.online_status {
  z-index: 10;
  left: 12;
  left: 12px;
  top: 1px;
  height: 12px;
  width: 12px;
  &_offline {
    background-color: gray;
  }
  &_online {
    background-color: rgb(9, 160, 9);
  }
}

.tabs_navigation {
  li {
    a {
      font-size: 14px !important;
    }
  }
}

.reason {
  max-width: 450px;
  max-height: 35px;
  white-space: normal;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;

    &-thumb {
      background: #5e72e4;
      border-radius: 12px;
    }

    &-track {
      background: rgba(203, 203, 203, 0.703);
      border-radius: 12px;
      margin-left: 10px;
    }
  }
}

.truncate {
  overflow: hidden;
  max-width: 15rem;
  text-overflow: ellipsis;
}
.message_file_icon {
  height: 6.5rem;
  width: 6.5rem;
}
.image_attachment {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: cover;
  border-radius: 2px;

  @media (max-width: 426px) {
    max-width: 230px;
  }
  @media (max-width: 375.5px) {
    max-width: 210px;
  }
  @media (max-width: 325px) {
    max-width: 164px;
  }
}
.video_container {
  max-width: 355px;
  @media screen and (max-width: 1026px) {
    max-width: 280px !important;
  }
  @media screen and (max-width: 769px) {
    max-width: 240px !important;
  }
  @media screen and (max-width: 426px) {
    max-width: 240px !important;
  }
  @media screen and (max-width: 375.5px) {
    max-width: 210px !important ;
  }
  @media screen and (max-width: 325px) {
    max-width: 164px !important;
  }
}
.red-icon {
  color: rgba(255, 65, 65, 0.99) !important;
}
.form-controll_title {
  color: black !important;
}
.DraftEditor-root {
  z-index: 0 !important;
}
.attachment_w {
  @media (max-width: 1026px) {
    max-width: 280px !important;
  }
  @media (max-width: 769px) {
    max-width: 240px !important;
  }
  @media (max-width: 426px) {
    max-width: 240px !important;
  }
  @media screen and (max-width: 379px) {
    max-width: 150px !important;
  }
}

.article_media {
  width: -moz-fit-content;
  width: fit-content;
}

@media (min-width: 540px) {
  .w-sm-50 {
    width: 50% !important;
  }
}
