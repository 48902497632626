.chatApp {
  // height: 800px !important;
  &__contents {
    border-radius: 12px !important;
    // height: 100% !important;
  }
}
.chatAppMessages {
  &__contents {
    margin-top: 10px;

    height: 550px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &-message {
      border-radius: 12px !important;
      width: 60% !important;

      @media (max-width: 768px) {
        width: 80% !important;
      }
      @media (max-width: 575px) {
        width: 100% !important;
      }
      &-text{
        overflow-x: hidden;
      }
    }
    // &-messageLeft {
    //   border-radius: 12px !important;
    //   width: 60% !important;
    //   @media (max-width: 768px) {
    //     width: 80% !important;
    //   }
    //   @media (max-width: 575px) {
    //     width: 100% !important;
    //   }
    // }
    // &-messageRight {
    //   border-radius: 12px !important;
    //   width: 60%;
    //   position: absolute;
    //   right: 1%;
    //   @media (max-width: 768px) {
    //     width: 80% !important;
    //   }
    //   @media (max-width: 575px) {
    //     width: 90% !important;
    //   }
    // }
    &-textImg {
      border-radius: 12px !important;
      border: 1px solid #cfcfe9;
    }
  }
  &__topbar {
    background-color: #b0eed0;
    border-radius: 12px !important;
  }
  &__bottombar {
    background-color: #cfcfe9;
    border-radius: 12px !important;
    position: absolute;
    bottom: 0;
    min-width: 98% !important;
    &-input {
      background-color: #6d6d8a;
      color: white;
    }
  }
}


// ------------------------------------------------------------------------
//chat app
.chat-container {
  height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}

.message {
  padding: 5px 10px;
  margin: 2px;
  border-radius: 5px;
  word-wrap: break-word;
  max-width: 80%;
  width: max-content;
}

.message-left {
  background-color: #f0f0f0;
}

.message-right {
  background-color: #5e72e4;
  color: #fff;
}

.message-bubble-right {
  width: 14px;
  height: 10px;
  background: #007bff;
  border-radius: 0% 0% 100% 0%;
  margin-top: 5px;
  margin-left: -9px;
}
.message-bubble-left {
  width: 14px;
  height: 10px;
  background: #f0f0f0;
  border-radius: 0% 0% 0% 100%;
  margin-top: 5px;
  margin-right: -9px;
}

.right {
  justify-content: end;
  flex-direction: row-reverse;
}

.chat-container {
  height: 65vh;
  display: flex;
  flex-direction: column;
}

/* MessageInput*/
.message-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.message-send-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 1.2%;
  margin-bottom: 5px;
  font-size: 14px;
  width: 30px;
  height: 30px;
}

.truncate-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.message-input {
  margin: 0;
  width: 100%;
  border: 0;

  resize: none;
  background-color: transparent;
  padding-right: 2.5rem;
  font-size: 14px;
  line-height: 1.5;
  max-height: 200px;
  overflow-y: scroll;
  /* Firefox */
  & {
      scrollbar-width: thin;
      scrollbar-color: #c3c1d8 #f2fdff;
  }

  /* Chrome, Edge and Safari */
  &::-webkit-scrollbar {
      height: 7px;
      width: 7px;
  }
  &::-webkit-scrollbar-track {
      background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      background-color: #c3c1d8;
  }

  &::-webkit-scrollbar-thumb:hover {
      background-color: #e1ddf1;
      cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:active {
      background-color: #c3c1d8;
  }
}
.chat_offer_card_width {
  width: 84% !important;
  @media screen and (max-width:1644px) {
    width: 60% !important;
    
  }
  @media screen and (max-width:769px) {
    width: 80% !important;
    
  }
  @media screen and (max-width:574.5px) {
    width: 99% !important;
    
  }
  
  
}